import React, { useEffect, lazy, Suspense } from "react";
import { createRoot } from "react-dom/client"; // Adjusted import
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";

import "./assets/css/home.css";

const Home = lazy(() => import("./pages/Home"));
const Proyecto = lazy(() => import("./pages/Proyecto"));
const Acuario = lazy(() => import("./pages/Acuario"));
const Mazatun = lazy(() => import("./pages/Mazatun"));
const Gaviana = lazy(() => import("./pages/Gaviana"));
const Palmares = lazy(() => import("./pages/Palmares"));
const Tianguis = lazy(() => import("./pages/Tianguis"));
const Vittore = lazy(() => import("./pages/Vittore"));
const Gavias = lazy(() => import("./pages/Gavias"));

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const App = () => (
  <BrowserRouter>
    <ScrollToTop />
    <Suspense fallback={<h1>Cargando...</h1>}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="proyecto" element={<Proyecto />} />
        <Route path="acuario" element={<Acuario />} />
        <Route path="mazatun" element={<Mazatun />} />
        <Route path="gaviana" element={<Gaviana />} />
        <Route path="palmares" element={<Palmares />} />
        <Route path="tianguis" element={<Tianguis />} />
        <Route path="vittore" element={<Vittore />} />
        <Route path="gavias" element={<Gavias />} />
      </Routes>
    </Suspense>
  </BrowserRouter>
);

// Utiliza createRoot para renderizar la aplicación en el elemento con id "root"
createRoot(document.getElementById("root")).render(<App />);
